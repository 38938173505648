<template>
  <v-data-table :headers="headers" :items="items" disable-pagination hide-default-footer>
    <template v-slot:item.action="{ item }">
      <v-btn text color="red" @click="execute(item.token)">
        <v-icon left>{{ icon }}</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
export default {
  props: {
    headers: {
      type: Array
    },
    actionIcon: {
      type: String
    },
    items: {
      type: Array
    },
    action: {
      type: Function
    }
  },
  computed: {
    icon() {
      if (!this.actionIcon) return 'mdi-plus';
      return this.actionIcon;
    }
  },
  methods: {
    async execute(value) {
      if (this.action) {
        await this.action(value);
        this.$emit('close');
      }
    }
  }
};
</script>
