<template>
  <v-dialog v-model="dialog" max-width="1024" persistent>
    <template v-slot:activator="{ on }">
      <v-btn color="red" text v-on="on">
        <v-icon left>mdi-plus</v-icon>
        Добавить пользователя
      </v-btn>
    </template>
    <v-card outlined>
      <v-stepper v-model="stepper" outlined>
        <!-- <v-stepper-header>
          <v-stepper-step :complete="stepper > 1" step="1">Регистрация</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="stepper > 2" step="2">Права доступа</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :editable="stepper >= 3" :complete="stepper > 3" step="3">Привязка по дирекции</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :editable="stepper >= 3" :complete="stepper > 4" step="4">Привязка по станции</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :editable="stepper >= 3" :complete="stepper > 5" step="5">Привязка по кафе</v-stepper-step>
        </v-stepper-header> -->
        <v-stepper-header>
          <v-stepper-step
            :editable="stepper >= 2"
            v-for="item in this.stepsByPermissionUser"
            :complete="item.step === stepper"
            :step="item.step"
            :key="item.key"
            >{{ item.name }}</v-stepper-step
          >
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content v-for="step in this.stepsByPermissionUser" :key="step.key" :step="step.step" outlined>
            <v-card v-if="step.key === 1" class="px-2 pt-1 pb-3" outlined>
              <v-form ref="form" @submit.prevent="checkInput">
                <v-card-text>
                  <span>Логин</span>
                  <v-text-field v-model="user.username" :rules="rules" class="mt-2" dense hide-details="auto" outlined />
                  <div class="mt-5">Пароль</div>
                  <v-text-field v-model="user.password" :rules="rules" class="mt-2" dense hide-details="auto" outlined />
                  <div class="mt-5">ФИО</div>
                  <v-text-field v-model="user.fio" :rules="rules" class="mt-2" dense hide-details="auto" outlined />
                  <div class="mt-5">Роль</div>
                  <v-text-field v-model="user.description" :rules="rules" class="mt-2" dense hide-details="auto" outlined />
                  <div class="mt-5">Почта</div>
                  <v-text-field v-model="user.mail" :rules="rules" class="mt-2" dense hide-details="auto" outlined />
                  <div class="mt-5">Телефон</div>
                  <v-text-field v-model="user.phone" :rules="rules" class="mt-2" dense hide-details="auto" outlined />
                  <div class="mt-5">Стартовая страница</div>
                  <v-select :items="getDefaultPageItems()" v-model="user.defaultPage" :rules="rules" class="mt-2" dense hide-details="auto" outlined />
                </v-card-text>
                <v-card-actions class="mt-6">
                  <v-spacer></v-spacer>
                  <v-btn @click="closeDialog" color="red" text>Отмена</v-btn>
                  <v-btn :loading="loading" @click="addUserClick" color="red" depressed>Добавить</v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
            <v-card v-if="step.key === 2" class="px-2 pt-1 pb-3" outlined>
              <CustomTable actionIcon="mdi-delete-outline" :headers="permissionHeader" :items="userPermissions" :action="deletePermissionClick" />
              <v-card-actions class="mt-6">
                <v-spacer></v-spacer>
                <DialogToAdd :headers="permissionHeader" :items="permissions" :action="addPermissionClick" />
                <v-btn @click="stepper += 1">Вперед</v-btn>
                <v-btn @click="closeDialog" color="red" text>закрыть</v-btn>
              </v-card-actions>
            </v-card>
            <v-card v-if="step.key === 3" class="px-2 pt-1 pb-3" outlined>
              <CustomTable actionIcon="mdi-delete-outline" :headers="directionHeader" :items="userDirections" :action="deleteDirectionClick" />
              <v-card-actions class="mt-6">
                <v-spacer></v-spacer>
                <DialogToAdd :headers="directionHeader" :items="directions" :action="addDirectionClick" />
                <v-btn @click="stepper += 1">Вперед</v-btn>
                <v-btn @click="stepper -= 1">Назад</v-btn>
                <v-btn @click="closeDialog" color="red" text>закрыть</v-btn>
              </v-card-actions>
            </v-card>
            <v-card v-if="step.key === 4" class="px-2 pt-1 pb-3" outlined>
              <CustomTable actionIcon="mdi-delete-outline" :headers="stationHeader" :items="userStations" :action="deleteStationClick" />
              <v-card-actions class="mt-6">
                <v-spacer></v-spacer>
                <DialogToAdd :headers="stationHeader" :items="stations" :action="addStationClick" />
                <v-btn @click="stepper += 1">Вперед</v-btn>
                <v-btn @click="stepper -= 1">Назад</v-btn>
                <v-btn @click="closeDialog" color="red" text>закрыть</v-btn>
              </v-card-actions>
            </v-card>
            <v-card v-if="step.key === 5" class="px-2 pt-1 pb-3" outlined>
              <CustomTable actionIcon="mdi-delete-outline" :headers="cafeHeader" :items="userCafes" :action="deleteCafeClick" />
              <v-card-actions class="mt-6">
                <v-spacer></v-spacer>
                <DialogToAdd :headers="cafeHeader" :items="cafes" :action="addCafeClick" />
                <v-btn @click="stepper -= 1">Назад</v-btn>
                <v-btn @click="closeDialog" color="red" text>закрыть</v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
    <div class="notification">
      <Notification />
    </div>
  </v-dialog>
</template>
<script>
import Notification from '@/components/Notification';
import { mapActions, mapGetters } from 'vuex';
import Helper from './../helper/index';
import CustomTable from './CustomTable.vue';
import DialogToAdd from './DialogToAdd.vue';

export default {
  data() {
    return {
      user: {
        username: '',
        password: '',
        fio: '',
        description: '',
        defaultPage: '',
        mail: '',
        phone: ''
      },
      defaultSteps: [
        {
          step: 1,
          name: 'Личные данные',
          key: 1
        },
        {
          step: 2,
          name: 'Права доступа',
          key: 2
        }
      ],
      userStations: [],
      userCafes: [],
      userDirections: [],
      userPermissions: [],
      userToken: '',
      stepper: 1,
      rules: [(v) => !!v || 'Обязательное поле'],
      validationRules: {
        notEmpty: [(v) => !!v || 0 || 'Обязательное поле'],
        emailForm: [
          (value) => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || 'Неверный формат';
          }
        ],
        nameForm: [
          (value) => {
            const pattern = /[ЁёА-я,A-Za-z .-]+$/;
            return pattern.test(value) || 'Неверный формат';
          }
        ],
        phoneRules: [(v) => (v.length > 10 && v.length < 12) || 'Проверьте правильность указанного номера']
      },
      loading: false,
      dialog: false
    };
  },
  components: {
    Notification,
    CustomTable,
    DialogToAdd
  },
  computed: {
    stepsByPermissionUser() {
      let stepperArray = [];
      stepperArray.push(...this.defaultSteps);
      if (this.checkPermission([128, 64])) {
        stepperArray.push({ step: stepperArray.length + 1, name: 'Дирекции', key: 3 });
      }
      if (this.checkPermission([128, 32])) {
        stepperArray.push({ step: stepperArray.length + 1, name: 'Станции', key: 4 });
      }
      if (this.checkPermission([128, 8])) {
        stepperArray.push({ step: stepperArray.length + 1, name: 'Кафе', key: 5 });
      }
      return stepperArray;
    },
    permissionHeader() {
      return Helper.getPermissionTable();
    },
    cafeHeader() {
      return Helper.getCafeTable();
    },
    stationHeader() {
      return Helper.getStationTable();
    },
    directionHeader() {
      return Helper.getDirectionTable();
    },
    permissions() {
      if (!this.permissionList) return [];
      let arr = [];
      this.permissionList.forEach((perm) => {
        if (this.userPermissions.find((userPerm) => userPerm.token === perm.token) == undefined) arr.push(perm);
      });
      return arr;
    },
    directions() {
      if (!this.directionList) return [];
      let arr = [];
      this.directionList.forEach((direction) => {
        if (this.userDirections.find((userDir) => userDir.token === direction.token) == undefined) arr.push(direction);
      });
      return arr;
    },
    cafes() {
      if (!this.cafeList) return [];
      let arr = [];
      this.cafeList.forEach((cafe) => {
        if (this.userCafes.find((c) => c.token === cafe.token) == undefined) arr.push(cafe);
      });
      return arr;
    },
    stations() {
      if (!this.stationsList) return [];
      let arr = [];
      this.stationsList.forEach((station) => {
        if (this.userStations.find((s) => s.token === station.token) == undefined) arr.push(station);
      });
      return arr;
    },
    ...mapGetters('user', ['userResult']),
    ...mapGetters('user', ['userData']),
    ...mapGetters('user', ['permissionResult']),
    ...mapGetters('auth', ['userPerm']),
    ...mapGetters('cafe', ['cafeList']),
    ...mapGetters('station', ['stationsList']),
    ...mapGetters('direction', ['directionList']),
    ...mapGetters('user', ['permissionList']),
    ...mapGetters('user', ['newPermission']),
    ...mapGetters('user', ['deletedPermission']),
    ...mapGetters('user', ['newStation']),
    ...mapGetters('user', ['deletedStation']),
    ...mapGetters('user', ['newCafe']),
    ...mapGetters('user', ['deletedCafe']),
    ...mapGetters('user', ['newDirection']),
    ...mapGetters('user', ['deletedDirection'])
  },
  methods: {
    checkPermission(value) {
      return this.userPerm.find((el) => value.includes(el)) !== undefined;
    },
    checkInput() {
      return true;
    },
    addUserClick() {
      // if (this.$refs.form.validate()) {
      this.loading = true;
      this.addUser(this.user)
        .then(() => {
          if (this.userResult) {
            this.loading = false;
            this.stepper = 2;
            this.user.token = this.userData.token;
            this.userToken = this.userData.token;
            this.fetchPermission();
          }
        })
        .catch(() => {
          this.loading = false;
        });
      // }
    },
    deletePermissionClick(value) {
      this.deleteUserPermission({ userToken: this.userToken, permissionToken: value })
        .then(() => {
          this.userPermissions = this.userPermissions.filter((perm) => perm.token !== this.deletedPermission.token);
          this.showNotification('Пользователь успешно отредактирован', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.showNotification('Возникла ошибка', 'error', '2000', 'mdi-check');
        });
    },
    deleteDirectionClick(value) {
      this.deleteUserDirection({ userToken: this.userToken, directionToken: value })
        .then(() => {
          this.userDirections = this.userDirections.filter((dir) => dir.token !== this.deletedDirection.token);

          this.showNotification('Пользователь успешно отредактирован', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.showNotification('Возникла ошибка', 'error', '2000', 'mdi-check');
        });
    },
    deleteCafeClick(value) {
      this.deleteUserCafe({ userToken: this.userToken, cafeToken: value })
        .then(() => {
          this.userCafes = this.userCafes.filter((cafe) => cafe.token !== this.deletedCafe.token);
          this.showNotification('Пользователь успешно отредактирован', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.showNotification('Возникла ошибка', 'error', '2000', 'mdi-check');
        });
    },
    deleteStationClick(value) {
      this.deleteUserStation({ userToken: this.userToken, stationToken: value })
        .then(() => {
          this.userStations = this.userStations.filter((station) => station.token !== this.deletedStation.token);

          this.showNotification('Пользователь успешно отредактирован', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.showNotification('Возникла ошибка', 'error', '2000', 'mdi-check');
        });
    },
    addPermissionClick(value) {
      this.createUserPermission({ userToken: this.userToken, permissionToken: value })
        .then(() => {
          this.userPermissions.push(this.newPermission);
          this.showNotification('Пользователь успешно отредактирован', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.showNotification('Возникла ошибка', 'error', '2000', 'mdi-check');
        });
    },
    addDirectionClick(value) {
      this.createUserDirection({ userToken: this.userToken, directionToken: value })
        .then(() => {
          this.userDirections.push(this.newDirection);
          this.showNotification('Пользователь успешно отредактирован', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.showNotification('Возникла ошибка', 'error', '2000', 'mdi-check');
        });
    },
    addCafeClick(value) {
      this.createUserCafe({ userToken: this.userToken, cafeToken: value })
        .then(() => {
          this.userCafes.push(this.newCafe);
          this.showNotification('Пользователь успешно отредактирован', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.showNotification('Возникла ошибка', 'error', '2000', 'mdi-check');
        });
    },
    addStationClick(value) {
      this.createUserStation({ userToken: this.userToken, stationToken: value })
        .then(() => {
          this.userStations.push(this.newStation);
          this.showNotification('Пользователь успешно отредактирован', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.showNotification('Возникла ошибка', 'error', '2000', 'mdi-check');
        });
    },

    fetchPermission() {
      this.getPermission();
    },
    fetchDirections() {
      this.fetchDirectionList();
    },

    fetchCafes() {
      this.getPermissionCafeList();
    },
    closeDialog() {
      this.loading = false;
      this.dialog = false;
      this.user.username = '';
      this.user.password = '';
      this.user.fio = '';
      this.user.description = '';
      this.user.defaultPage = '';
      this.user.mail = '';
      this.user.phone = '';
      this.userToken = '';
    },
    showNotification(message, color, timer, icon) {
      this.showSnack({
        message,
        color,
        timer,
        icon
      });
    },
    getDefaultPageItems() {
      return Helper.getDefaultPageList();
    },
    ...mapActions('user', ['addUser']),
    ...mapActions('user', ['getPermission']),
    ...mapActions('user', ['createUserPermission']),
    ...mapActions('user', ['createUserDirection']),
    ...mapActions('user', ['createUserStation']),
    ...mapActions('user', ['createUserCafe']),
    ...mapActions('user', ['fetchUserList']),
    ...mapActions('user', ['deleteUserPermission']),
    ...mapActions('user', ['deleteUserDirection']),
    ...mapActions('user', ['deleteUserStation']),
    ...mapActions('user', ['deleteUserCafe']),
    ...mapActions('notification', ['showSnack']),
    ...mapActions('direction', ['fetchDirectionList']),
    ...mapActions('cafe', ['getPermissionCafeList'])
  },
  mounted() {
    this.fetchDirections();
    this.fetchCafes();
  }
};
</script>
