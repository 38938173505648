const ACTIVE_PROPERTY = [
  { value: true, text: 'Да' },
  { value: false, text: 'Нет' }
];
const DEFAULT_PAGE_LIST = [
  { value: 'kitchen', text: 'Кухня' },
  { value: 'operator', text: 'Оператор' },
  { value: 'amount', text: 'Изменение остатков' },
  { value: 'control', text: 'Меню редактирования ресторана' },
  { value: 'stations', text: 'Станции' },
  { value: 'qr', text: 'Qr' },
  { value: 'users', text: 'Пользователи' },
  { value: 'courier', text: 'Курьеры' }
];

//~ TABLES ~
const USER_TABLE = [
  { text: 'Имя пользователя', value: 'fio' },
  { text: 'Логин', value: 'username' },
  { text: 'Описание', value: 'description' },
  { text: 'Активен', value: 'isActive' },
  { text: '', value: 'action' }
];
const SUPERADMIN_USER_TABLE = [
  { text: 'Имя пользователя', value: 'fio' },
  { text: 'Логин', value: 'username' },
  { text: 'Описание', value: 'description' },
  { text: 'Активен', value: 'isActive' },
  { text: 'Забанен', value: 'disabledBySuperior' },
  { text: '', value: 'action' }
];
const PERMISSION_TABLE = [
  { text: 'Название', value: 'desc' },
  { text: '', value: 'action' }
];
const STATION_TABLE = [
  { text: 'Название', value: 'nameExpFullRu' },
  { text: '', value: 'action' }
];
const DIRECTION_TABLE = [
  { text: 'Название', value: 'nameRu' },
  { text: '', value: 'action' }
];
const CAFE_TABLE = [
  { text: 'Название', value: 'nameRu' },
  { text: '', value: 'action' }
];

function getUserTable() {
  return USER_TABLE;
}
function getSuperadminUserTable() {
  return SUPERADMIN_USER_TABLE;
}
function getPermissionTable() {
  return PERMISSION_TABLE;
}
function getDirectionTable() {
  return DIRECTION_TABLE;
}
function getStationTable() {
  return STATION_TABLE;
}
function getCafeTable() {
  return CAFE_TABLE;
}
function getActivePropertyList() {
  return ACTIVE_PROPERTY;
}
function getActivePropertyValue(value) {
  return getTextByValue(ACTIVE_PROPERTY, value);
}

function getDefaultPageList() {
  return DEFAULT_PAGE_LIST;
}
function getDefaultPageValue(value) {
  return getTextByValue(DEFAULT_PAGE_LIST, value);
}

function getTextByValue(list, value) {
  let name = value;
  for (let i = 0; i < list.length; i++) {
    if (list[i].value === value) {
      name = list[i].text;
      break;
    }
  }
  return name;
}

export default {
  getUserTable,
  getSuperadminUserTable,
  getPermissionTable,
  getDirectionTable,
  getStationTable,
  getCafeTable,
  getActivePropertyValue,
  getActivePropertyList,
  getDefaultPageValue,
  getDefaultPageList
};
