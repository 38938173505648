<template>
  <div class="userControl ma-3">
    <v-container fluid>
      <h1 class="mx-2">Пользователи</h1>
      <v-row class="my-2">
        <v-col>
          <UserCreateDialog v-if="checkPermission([128, 64, 32, 8])" />
        </v-col>
      </v-row>
      <v-data-table :headers="headers" :items="userList" disable-pagination hide-default-footer>
        <template v-slot:item.isActive="{ item }">
          {{ activeProperty(item.isActive) }}
        </template>
        <template v-slot:item.disabledBySuperior="{ item }">
          {{ activeProperty(item.disabledBySuperior) }}
        </template>
        <template v-slot:item.action="{ item }">
          <UserEditDialog v-if="checkPermission([128, 64, 32, 8])" :user="item" />
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import UserCreateDialog from './UserCreateDialog.vue';
import UserEditDialog from './UserEditDialog.vue';

import helper from '../helper';
export default {
  data() {
    return {};
  },
  components: {
    UserCreateDialog,
    UserEditDialog
  },
  computed: {
    headers() {
      if (this.checkPermission([128])) return helper.getSuperadminUserTable();
      return helper.getUserTable();
    },

    users() {
      if (!this.userList) return [];
      return this.userList;
    },
    ...mapGetters('auth', ['userPerm']),
    ...mapGetters('user', ['userList'])
  },
  methods: {
    checkPermission(value) {
      return this.userPerm.find((el) => value.includes(el)) !== undefined;
    },
    activeProperty(property) {
      return helper.getActivePropertyValue(property);
    },
    getUserList() {
      this.fetchUserList();
      this.getPermission();
    },
    fetchStations() {
      this.getPermissionsStations();
    },
    ...mapActions('user', ['fetchUserList']),
    ...mapActions('user', ['getUserPermission']),
    ...mapActions('station', ['getPermissionsStations']),
    ...mapActions('user', ['getPermission'])
  },
  mounted() {
    this.getUserList();
    this.fetchStations();
  }
};
</script>
<style scoped>
.navbar {
}
</style>
