<template>
  <v-main class="home">
    <Navbar />
    <Users />
    <!-- <router-view :key="$route.fullPath" /> -->
  </v-main>
</template>

<script>
import Navbar from '@/components/NavBar';
import Users from './views/Users.vue';
export default {
  components: {
    Navbar,
    Users
  }
};
</script>
<style scoped>
.home {
  background-color: rgba(57, 74, 88, 0.2);
  font-family: RussianRail;
  height: 100%;
}
</style>
