<template>
  <v-dialog v-model="dialog" max-width="900" persistent>
    <template v-slot:activator="{ on }">
      <v-btn color="red" text v-on="on">
        <v-icon left>mdi-plus</v-icon>
        Подробнее
      </v-btn>
    </template>
    <v-stepper v-model="stepper" outlined>
      <v-stepper-header>
        <v-stepper-step editable v-for="item in this.stepsByPermissionUser" :complete="item.step === stepper" :step="item.step" :key="item.key">{{
          item.name
        }}</v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content v-for="step in this.stepsByPermissionUser" :key="step.key" :step="step.step" outlined>
          <v-card v-if="step.key === 1" class="px-2 pt-1 pb-3" outlined>
            <v-form ref="form" @submit.prevent="checkInput">
              <v-card-title class="text-h5 font-weight-bold">Редактирование пользователя</v-card-title>
              <v-card-text class="mt-1">
                {{ userPermissions }}
                <v-row no-gutters class="RowField" v-for="item in getUserEditFields" :key="item.id">
                  <v-col>
                    <div class="mt-5 mb-2">{{ item.name }}</div>
                    <v-text-field
                      v-if="item.type !== 'defaultPage' && item.type !== 'isActive' && item.type !== 'disabledBySuperior' && item.type !== 'permissions'"
                      dense
                      hide-details="auto"
                      outlined
                      :label="editedUser[item.in]"
                      v-model="modifyUser[item.out]"
                      class="custom-label-color"
                    ></v-text-field>
                    <v-select
                      v-if="item.type === 'defaultPage'"
                      dense
                      hide-details="auto"
                      outlined
                      v-bind:label="getDefaultPageName(editedUser[item.in])"
                      :items="getDefaultPageItems(item.in)"
                      v-model="modifyUser[item.out]"
                      class="custom-label-color"
                    ></v-select>
                    <v-select
                      v-if="item.type === 'isActive'"
                      dense
                      hide-details="auto"
                      outlined
                      :items="getBooleanItems(item.in)"
                      v-bind:label="getBooleanName(editedUser[item.in])"
                      v-model="modifyUser[item.out]"
                      class="custom-label-color"
                    ></v-select>
                    <v-select
                      v-if="item.type === 'disabledBySuperior'"
                      dense
                      hide-details="auto"
                      outlined
                      :items="getBooleanItems(item.in)"
                      v-bind:label="getBooleanName(editedUser[item.in])"
                      v-model="modifyUser[item.out]"
                      class="custom-label-color"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="mt-6">
                <v-spacer></v-spacer>
                <v-btn @click="closeDialog" color="red" text>закрыть</v-btn>
                <v-btn :loading="loading" @click="editUserClick" color="red" depressed>Сохранить</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
          <v-card v-if="step.key === 2" class="px-2 pt-1 pb-3" outlined>
            <CustomTable actionIcon="mdi-delete-outline" :headers="permissionHeader" :items="userPermissions" :action="deletePermissionClick" />
            <v-card-actions class="mt-6">
              <v-spacer></v-spacer>
              <DialogToAdd :headers="permissionHeader" :items="permissions" :action="addPermissionClick" />
              <v-btn @click="closeDialog" color="red" text>закрыть</v-btn>
            </v-card-actions>
          </v-card>
          <v-card v-if="step.key === 3" class="px-2 pt-1 pb-3" outlined>
            <CustomTable actionIcon="mdi-delete-outline" :headers="directionHeader" :items="userDirections" :action="deleteDirectionClick" />
            <v-card-actions class="mt-6">
              <v-spacer></v-spacer>
              <DialogToAdd :headers="directionHeader" :items="directions" :action="addDirectionClick" />
              <v-btn @click="closeDialog" color="red" text>закрыть</v-btn>
            </v-card-actions>
          </v-card>
          <v-card v-if="step.key === 4" class="px-2 pt-1 pb-3" outlined>
            <CustomTable actionIcon="mdi-delete-outline" :headers="stationHeader" :items="userStations" :action="deleteStationClick" />
            <v-card-actions class="mt-6">
              <v-spacer></v-spacer>
              <DialogToAdd :headers="stationHeader" :items="stations" :action="addStationClick" />
              <v-btn @click="closeDialog" color="red" text>закрыть</v-btn>
            </v-card-actions>
          </v-card>
          <v-card v-if="step.key === 5" class="px-2 pt-1 pb-3" outlined>
            <CustomTable actionIcon="mdi-delete-outline" :headers="cafeHeader" :items="userCafes" :action="deleteCafeClick" />
            <v-card-actions class="mt-6">
              <v-spacer></v-spacer>
              <DialogToAdd :headers="cafeHeader" :items="cafes" :action="addCafeClick" />
              <v-btn @click="closeDialog" color="red" text>закрыть</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <div class="notification">
      <Notification />
    </div>
  </v-dialog>
</template>
<script>
import Notification from '@/components/Notification';
import { mapActions, mapGetters } from 'vuex';
import fields from './../helper/fields.json';
import Helper from './../helper/index';
import CustomTable from './CustomTable.vue';
import DialogToAdd from './DialogToAdd.vue';

export default {
  data() {
    return {
      editedUser: {},
      dialogAdd: false,
      rules: [(v) => !!v || 'Обязательное поле'],
      loading: false,
      dialog: false,
      modifyUser: {},
      userStations: [],
      userCafes: [],
      userDirections: [],
      userPermissions: [],
      stepper: 1,
      defaultSteps: [
        {
          step: 1,
          name: 'Личные данные',
          key: 1
        },
        {
          step: 2,
          name: 'Права доступа',
          key: 2
        }
      ]
    };
  },
  props: {
    user: {
      type: Object
    }
  },
  computed: {
    getUserEditFields() {
      return { ...JSON.parse(JSON.stringify(fields))[1] };
    },
    stepsByPermissionUser() {
      let stepperArray = [];
      stepperArray.push(...this.defaultSteps);
      if (this.checkPermission([128, 64])) {
        stepperArray.push({ step: stepperArray.length + 1, name: 'Дирекции', key: 3 });
      }
      if (this.checkPermission([128, 32])) {
        stepperArray.push({ step: stepperArray.length + 1, name: 'Станции', key: 4 });
      }
      if (this.checkPermission([128, 8])) {
        stepperArray.push({ step: stepperArray.length + 1, name: 'Кафе', key: 5 });
      }
      return stepperArray;
    },
    permissionHeader() {
      return Helper.getPermissionTable();
    },
    permissions() {
      if (!this.permissionList) return [];
      let arr = [];
      this.permissionList.forEach((perm) => {
        if (this.userPermissions.find((userPerm) => userPerm.token === perm.token) == undefined) arr.push(perm);
      });
      return arr;
    },
    directions() {
      if (!this.directionList) return [];
      let arr = [];
      this.directionList.forEach((direction) => {
        if (this.userDirections.find((userDir) => userDir.token === direction.token) == undefined) arr.push(direction);
      });
      return arr;
    },
    cafes() {
      if (!this.cafeList) return [];
      let arr = [];
      this.cafeList.forEach((cafe) => {
        if (this.userCafes.find((c) => c.token === cafe.token) == undefined) arr.push(cafe);
      });
      return arr;
    },
    stations() {
      if (!this.stationsList) return [];
      let arr = [];
      this.stationsList.forEach((station) => {
        if (this.userStations.find((s) => s.token === station.token) == undefined) arr.push(station);
      });
      return arr;
    },
    cafeHeader() {
      return Helper.getCafeTable();
    },
    stationHeader() {
      return Helper.getStationTable();
    },
    directionHeader() {
      return Helper.getDirectionTable();
    },
    ...mapGetters('auth', ['userPerm']),
    ...mapGetters('cafe', ['cafeList']),
    ...mapGetters('station', ['stationsList']),
    ...mapGetters('direction', ['directionList']),
    ...mapGetters('user', ['permissionList']),

    ...mapGetters('user', ['newPermission']),
    ...mapGetters('user', ['deletedPermission']),
    ...mapGetters('user', ['newStation']),
    ...mapGetters('user', ['deletedStation']),
    ...mapGetters('user', ['newCafe']),
    ...mapGetters('user', ['deletedCafe']),
    ...mapGetters('user', ['newDirection']),
    ...mapGetters('user', ['deletedDirection']),

    ...mapGetters('user', ['result'])
  },
  components: {
    Notification,
    CustomTable,
    DialogToAdd
  },
  methods: {
    getBooleanName: Helper.getActivePropertyValue,
    getDefaultPageName: Helper.getDefaultPageValue,
    checkPermission(value) {
      return this.userPerm.find((el) => value.includes(el)) !== undefined;
    },
    checkInput() {
      return true;
    },
    editUserClick() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.modifyUser.token = this.editedUser.token;
        this.editUser(this.modifyUser)
          .then(() => {
            // this.closeDialog();
            this.showNotification('Пользователь успешно отредактирован', 'success', '2000', 'mdi-check');
          })
          .catch(() => {
            this.closeDialog();
            this.showNotification('Возникла ошибка', 'error', '2000', 'mdi-check');
          });
      }
    },
    deletePermissionClick(value) {
      this.deleteUserPermission({ userToken: this.user.token, permissionToken: value })
        .then(() => {
          this.userPermissions = this.userPermissions.filter((perm) => perm.token !== this.deletedPermission.token);
          this.showNotification('Пользователь успешно отредактирован', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.showNotification('Возникла ошибка', 'error', '2000', 'mdi-check');
        });
    },
    deleteDirectionClick(value) {
      this.deleteUserDirection({ userToken: this.user.token, directionToken: value })
        .then(() => {
          this.userDirections = this.userDirections.filter((dir) => dir.token !== this.deletedDirection.token);

          this.showNotification('Пользователь успешно отредактирован', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.showNotification('Возникла ошибка', 'error', '2000', 'mdi-check');
        });
    },
    deleteCafeClick(value) {
      this.deleteUserCafe({ userToken: this.user.token, cafeToken: value })
        .then(() => {
          this.userCafes = this.userCafes.filter((cafe) => cafe.token !== this.deletedCafe.token);
          this.showNotification('Пользователь успешно отредактирован', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.showNotification('Возникла ошибка', 'error', '2000', 'mdi-check');
        });
    },
    deleteStationClick(value) {
      this.deleteUserStation({ userToken: this.user.token, stationToken: value })
        .then(() => {
          this.userStations = this.userStations.filter((station) => station.token !== this.deletedStation.token);

          this.showNotification('Пользователь успешно отредактирован', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.showNotification('Возникла ошибка', 'error', '2000', 'mdi-check');
        });
    },
    addPermissionClick(value) {
      this.createUserPermission({ userToken: this.user.token, permissionToken: value })
        .then(() => {
          this.userPermissions.push(this.newPermission);
          this.showNotification('Пользователь успешно отредактирован', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.showNotification('Возникла ошибка', 'error', '2000', 'mdi-check');
        });
    },
    addDirectionClick(value) {
      this.createUserDirection({ userToken: this.user.token, directionToken: value })
        .then(() => {
          this.userDirections.push(this.newDirection);
          this.showNotification('Пользователь успешно отредактирован', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.showNotification('Возникла ошибка', 'error', '2000', 'mdi-check');
        });
    },
    addCafeClick(value) {
      this.createUserCafe({ userToken: this.user.token, cafeToken: value })
        .then(() => {
          this.userCafes.push(this.newCafe);
          this.showNotification('Пользователь успешно отредактирован', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.showNotification('Возникла ошибка', 'error', '2000', 'mdi-check');
        });
    },
    addStationClick(value) {
      this.createUserStation({ userToken: this.user.token, stationToken: value })
        .then(() => {
          this.userStations.push(this.newStation);
          this.showNotification('Пользователь успешно отредактирован', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.showNotification('Возникла ошибка', 'error', '2000', 'mdi-check');
        });
    },

    closeDialog() {
      this.loading = false;
      this.dialog = false;
      this.modifyUser = {};
    },
    showNotification(message, color, timer, icon) {
      this.showSnack({
        message,
        color,
        timer,
        icon
      });
    },
    getBooleanItems() {
      return Helper.getActivePropertyList();
    },
    getDefaultPageItems() {
      return Helper.getDefaultPageList();
    },
    updateUser() {
      this.editedUser = this.user;
      this.user.stations.forEach((station) => this.userStations.push(station));
      this.user.directions.forEach((direction) => this.userDirections.push(direction));
      this.user.cafes.forEach((cafe) => this.userCafes.push(cafe));
      this.user.permissions.forEach((permission) => this.userPermissions.push(permission));
    },
    ...mapActions('user', ['editUser']),
    ...mapActions('user', ['createUserPermission']),
    ...mapActions('user', ['deleteUserPermission']),
    ...mapActions('user', ['createUserDirection']),
    ...mapActions('user', ['deleteUserDirection']),
    ...mapActions('user', ['createUserStation']),
    ...mapActions('user', ['deleteUserStation']),
    ...mapActions('user', ['createUserCafe']),
    ...mapActions('user', ['deleteUserCafe']),
    ...mapActions('notification', ['showSnack'])
  },
  mounted() {
    this.updateUser();
  }
};
</script>
