<template>
  <v-dialog v-model="dialog" :retain-focus="false" max-width="600">
    <template v-slot:activator="{ on }">
      <v-btn color="red" text v-on="on">
        <v-icon left>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      {{ dialog }}
      <CustomTable @close="dialog = false" :headers="headers" :items="items" :action="action" />
      <v-card-actions>
        <v-spacer />
        <v-btn @click="dialog = false" color="red" text>закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import CustomTable from './CustomTable.vue';

export default {
  data() {
    return {
      dialog: false
    };
  },
  props: {
    headers: {
      type: Array
    },
    items: {
      type: Array,
      required: true
    },
    action: {
      type: Function
    }
  },
  components: {
    CustomTable
  }
};
</script>
